body {
  margin: 0;
  font-family: 'Source Code Pro', monospace;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  width: 100%;
  min-height: 100%;
  transform: transale3d(0,0,0);
}

@media only screen and (max-width: 768px) {
  .mobile-hide {
    display: none;
  }

  .mobile-show {
    display: unset;
  }
}

@media only screen and (min-width: 769px) {
  
  .mobile-show {
    display: none;
  }
}

::selection {
  background: #000;
  color: #fff;
}
::-moz-selection {
  background: #000;
  color: #fff;
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* *, :after, :before {
  box-sizing: border-box;
} */