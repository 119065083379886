img {
  max-width: 100%;
}

.App {
  background: #fff;
}

header {
  position: fixed;
  width: calc(100% - 10%);
  z-index: 100;
  top: 20%;
  
  /* background: rgba(255,0,0,0.3); */
  mix-blend-mode: difference;
  color: #fff; 
}

header nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-transform: uppercase;
  text-decoration: none;
  /* transition: height 0.3s cubic-bezier(0.215, 0.610, 0.355, 1); */
  /* transition-delay: 0.1s; */
  /* transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1); */
}

header nav h1 {
  font-size: 25vw;
  letter-spacing: -0.08em;
  font-weight: 600;
  transform-origin: 0% 50%;
  margin-left: -30px;
  transform: translateZ(0);
}

header nav.sticky {
  height: 12vh;
}

header nav.sticky h1 {
  font-size: 2em;
  margin-left: 0px;
}

@media only screen and (min-width: 768px) {
  header nav h1 span, header li span {
    font-size: 0;
    transition: font-size 0.15s cubic-bezier(0.215, 0.610, 0.355, 1);
  }
}

@media only screen and (max-width: 768px) {
  header nav h1 span {
    display: none;
  }

  header ul li {
    display: inline;
  }
}

header ul li {
  margin: 0 5px;
}

header ul li:last-child {
  margin-right: 0;
}

header ul {
  list-style: none;
}

@media only screen and (min-width: 768px) {
  header ul li {
    display: inline;
  }
}

header h1 a:hover span, header li a:hover span {
  font-size: 1em;
  text-decoration: none !important;
}

header nav a {
  text-decoration: none;
  color: #fff;
}

.Wrapper {
  padding: 0 5%;
}

.intro {
  height: 80vh;
  line-height: 1.5;
}